<template>
    <div style="z-index: 200; padding: 14px; min-height: 500px;">
        <block v-if="loading"></block>
        <v-stepper v-model="step" class="white pa-4">
            <v-stepper-header style="flex-wrap: nowrap;">
                <v-stepper-step :complete="step > 1" step="1">
                    Carga de datos
                </v-stepper-step>
                <v-stepper-step :complete="step > 2" step="2">
                    Previsualización de los datos
                </v-stepper-step>
                <v-stepper-step :complete="step > 3" step="3">
                    Procesamiento de los datos
                </v-stepper-step>
            </v-stepper-header>
            
            <!-- <v-row>
                <v-col>
                    <v-btn class="btnclean" @click="resetAll" :disabled="loading">
                        <v-icon class="mr-2">mdi-broom</v-icon>Reiniciar
                    </v-btn>
                </v-col>
            </v-row> -->
            <div style="margin: 1rem 0; display: flex; flex-direction: row; justify-content: space-between;">
                <v-btn class="btnsave" @click="downloadTemplate" :disabled="loading">
                    <v-icon class="mr-2">mdi-download</v-icon>Descargar plantilla para importar
                </v-btn>
                <v-btn class="btnclean" @click="resetAll" :disabled="loading">
                    <v-icon class="mr-2">mdi-broom</v-icon>Reiniciar
                </v-btn>
            </div>
            
            <v-stepper-items>
                <!-- Step 1: Upload Excel -->
                <v-stepper-content step="1">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-step">
                                <v-card-title class="font-weight-bold text-nowrap">Importar datos de los clientes desde archivo</v-card-title>
                                <v-card-actions>
                                    <v-row>
                                        <v-col cols="12" class="col-input-file">
                                            <v-file-input
                                            style="min-width: 300px; width: 50%; display: flex; gap: 10px;"
                                            outlined
                                            dense
                                            prepend-icon="mdi-file-excel"
                                            v-model="selectedFile"
                                            accept=".xlsx, .xls, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            label="Seleccione hoja de calculo"
                                            :disabled="loading"
                                            >
                                            </v-file-input>
                                            <v-btn class="btn-import mb-3" @click="handleFileUpload" :disabled="loading || (selectedFile && uploadedFile)">
                                                <v-icon class="mr-2">mdi-import</v-icon>Cargar archivo
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                                <v-card-actions style="padding: 1rem; justify-content: flex-end;">
                                    <v-btn class="btncontinue" @click="nextStep" :disabled="loading">Continuar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-stepper-content>
                
                <!-- Step 2: Preview Data -->
                <v-stepper-content step="2">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-step">
                                <v-card-title class="font-weight-bold text-nowrap">Clientes importados desde el archivo seleccionado</v-card-title>
                                <v-card-text v-if="customersError" style="display: flex; flex-direction: row;">
                                    <v-icon color="red darken-4" style="margin: 10px; font-size: 20px;" >mdi-alert-circle</v-icon>
                                    <p style="margin-top: 20px; margin-bottom: 20px; font-size: 16px; color: #e3191e !important;">
                                        Algunos clientes importados presentan errores en la información.
                                    </p>
                                </v-card-text>
                                <v-data-table 
                                    :headers="tableHeaders" 
                                    :items="tableData"
                                    :items-per-page="50"
                                    :footer-props="{
                                        showFirstLastPage: true,
                                        firstIcon: 'mdi-page-first',
                                        lastIcon: 'mdi-page-last',                                            
                                        'items-per-page-options': [50, 100, 250],
                                        'items-per-page-text':'Clientes por pagina:',
                                    }"
                                    class="data-table"
                                >
                                    <template v-slot:item.status="{ item }">
                                        <v-icon v-if="item.error && item.error.isDuplicated" color="yellow darken-4" style="margin: 10px; font-size: 20px;" @click.prevent="showError(item.error)">mdi-alert-circle</v-icon>
                                        <v-icon v-if="item.error" color="red darken-4" style="margin: 10px; font-size: 20px;" @click.prevent="showError(item.error)">mdi-alert-circle</v-icon>
                                        <v-icon v-else style="margin: 10px; font-size: 20px;">mdi-check-circle</v-icon>
                                    </template>
                                </v-data-table>
                                <v-card-actions style="padding: 1rem; justify-content: space-between;">
                                    <v-btn class="btncontinue" @click="prevStep">Anterior</v-btn>
                                    <v-btn class="btnoption" @click="onClickProcess">Procesar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-stepper-content>
        
                    <!-- Step 3: Process Data -->
                <v-stepper-content step="3">
                    <v-row>
                        <v-col cols="12">
                            <v-card v-if="loading" class="card-step" style="min-height: 400px;">
                                <v-card-title class="font-weight-bold text-nowrap">Procesando la información importada</v-card-title>
                            </v-card>
                            <v-card v-else class="card-step" style="min-height: 400px;">
                                <v-card-title class="font-weight-bold text-nowrap">Estado del procesamiento de datos</v-card-title>

                                <v-card-text v-if="!processError && customersAllowed.length">
                                    <h3 style="margin-top: 20px; margin-bottom: 20px; font-size: 16px;">
                                        Se han procesado un total de {{ customersAllowed.length }} productos que fueron importados desde el archivo seleccionado.
                                    </h3>
                                    <v-card-actions style="padding: 0px; gap: 50px;">
                                        <v-btn class="btnoption" @click="showProcessed=!showProcessed" style="min-width: 100px;">
                                            {{!showProcessed ? 'Ver' : 'Ocultar'}}
                                            <v-icon v-if="!showProcessed" style="margin-left: 5px;">mdi-menu-down</v-icon>
                                            <v-icon v-else style="margin-left: 5px;">mdi-menu-up</v-icon>
                                        </v-btn>
                                        <v-btn class="btnoption" @click="exportToExcel('SUCCESS')" style="background-color: #00a178 !important;">
                                            <v-icon>mdi-file-excel</v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card-text>
                                <v-data-table 
                                    v-if="showProcessed"
                                    :headers="tableHeaders" 
                                    :items="customersAllowed"
                                    :items-per-page="50"
                                    :footer-props="{
                                        showFirstLastPage: true,
                                        firstIcon: 'mdi-page-first',
                                        lastIcon: 'mdi-page-last',                                            
                                        'items-per-page-options': [50, 100, 250],
                                        'items-per-page-text':'Productos por pagina:',
                                    }"
                                    class="data-table"
                                >
                                </v-data-table>

                                <v-card-text v-if="processError && customersNotAllowed.length">
                                    <h3 style="margin-top: 20px; margin-bottom: 20px; font-size: 16px; color: #e3191e !important;">
                                        No se logro procesar un total de {{ customersNotAllowed.length }} productos cargados, debido a la existencia de errores en la información.
                                    </h3>
                                    <v-card-actions style="padding: 0px; gap: 50px;">
                                        <v-btn class="btnoption" @click="showNotProcessed=!showNotProcessed" style="min-width: 100px;">
                                            {{!showNotProcessed ? 'Ver' : 'Ocultar'}}
                                            <v-icon v-if="!showNotProcessed" style="margin-left: 5px;">mdi-menu-down</v-icon>
                                            <v-icon v-else style="margin-left: 5px;">mdi-menu-up</v-icon>
                                        </v-btn>
                                        <v-btn class="btnoption" @click="exportToExcel('ERROR')" style="background-color: #00a178 !important;">
                                            <v-icon>mdi-file-excel</v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card-text>
                                <v-data-table 
                                    v-if="showNotProcessed"
                                    :headers="tableHeaders" 
                                    :items="customersNotAllowed"
                                    :items-per-page="50"
                                    :footer-props="{
                                        showFirstLastPage: true,
                                        firstIcon: 'mdi-page-first',
                                        lastIcon: 'mdi-page-last',                                            
                                        'items-per-page-options': [50, 100, 250],
                                        'items-per-page-text':'Productos por pagina:',
                                    }"
                                    class="data-table"
                                >
                                </v-data-table>

                                <v-card-text v-if="processError" style="display: flex; flex-direction: row;">
                                    <h3 style="margin-top: 20px; margin-bottom: 20px; font-size: 16px;">
                                        Ha ocurrido un error en el procesamiento, vuelva a intentarlo más tarde. 
                                    </h3> 
                                </v-card-text>

                                <v-card-text v-if="!customersAllowed.length && !customersNotAllowed.length && !processError" style="display: flex; flex-direction: row;">
                                    <h3 style="margin-top: 20px; margin-bottom: 20px; font-size: 16px;">
                                        No existen productos pendiente de procesamiento. 
                                    </h3> 
                                </v-card-text>
                                
                                <v-card-actions style="padding: 1rem; justify-content: space-between;">
                                    <v-btn class="btncontinue" @click="prevStep" :disabled="customersAllowed.length > 0 || customersNotAllowed.length > 0">Anterior</v-btn>
                                    <v-btn class="btncontinue" @click="resetAll">Volver al inicio</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-stepper-content>
                    
            </v-stepper-items>
        </v-stepper>
        <alert
            v-model="alert.show"
            @action="alert.action"
            :type="alert.type"
            :title="alert.title"
        >
            {{ alert.message }}
            <p style="margin-top: 1.5rem; color: white !important;">
                {{ alert.message2 ? alert.message2 : null }}
            </p>
        </alert>
    </div>
</template>
  
<script>
import Block from "@/components/Block";
import { mapGetters } from "vuex";
import * as XLSX from "xlsx";


export default {
    name: "ManageCustomersImport",
    props: [],
    components: {
        Block,
    },
    data() {
        return {
            step: 1,
            loading: false,
            id_bussiness: null,
            bussiness: null,
            id_branch: null,
            id_user: null,
            
            selectedFile: null,
            uploadedFile: false,
            tableHeaders: [],
            tableData: [],

            customersAllowed: [],
            customersNotAllowed: [],

            showProcessed: false,
            showNotProcessed: false,

            customersError: null,
            processError: null,

            alert: {
                show: false,
                type: "info",
                title: "",
                message: "",
                message2: "",
                action: (() => null),
            },  
        };
    },
    mounted() {
        this.id_bussiness = JSON.parse(localStorage.getItem("user")).businessid;
        this.business = JSON.parse(localStorage.getItem("user")).business;
        this.id_branch = JSON.parse(localStorage.getItem("user")).branch;
        this.id_user = JSON.parse(localStorage.getItem("user")).id;
    },
    computed: {
        /* checkCountry() {
            if (this.form.country == "El Salvador") return true;
            else return false;
        }, */
        departmentsMH() {
            return this.getCatalogMH("Departamento");
        },
        municipalitiesMH() {
            return this.getCatalogMH("Municipio");
            /* if (this.form.department) {
                const department = municipalities.filter(
                (mun) => mun.Departamento == this.form.department
                );
                return department;
            }
            return []; */
        },
        activitiesMH() {
            return this.getCatalogMH("ActividadEconomica");
        },
        countriesMH() {
            return this.getCatalogMH("Pais");
        },
        countriesList() {
            return this.$store.getters.getCountries;
        },
        ...mapGetters(["getCatalogMH"]),
    },
    watch: {
        tableData: {
            deep: true,
            inmediate: true,
            handler(newValue) {
                this.customersError = newValue.some((item) => item.error);
            }
        }
    },
    methods: {
        showAlert(type, title, message, action, message2) {
            type = type == null ? "info" : type;
            this.alert.type = type;
            this.alert.title = title;
            this.alert.message = message;
            this.alert.message2 = message2 ? message2 : null;
            this.alert.action = action ? action : (() => null);
            this.alert.show = true;
        },
        nextStep() {
            if(this.step === 1) {
                if(!this.uploadedFile) {
                    this.showAlert("warning", "Advertencia", "Debe seleccionar y cargar un archivo antes continuar.");
                    return;
                }
                this.handleDataLoad();
                this.step++;
            }
            else {
                this.step++;
            }
        },
        prevStep() {
            this.step--;
        },
        async handleFileUpload() {
            if (!this.selectedFile) {  
                this.showAlert("warning", "Advertencia", "Por favor seleccionar un archivo.");
                return;
            }
            try {
                this.loading = true;
                this.resetData();
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: "array" });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    //const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                    this.tableHeaders = jsonData[0].map((header) => ({ text: header, value: header }));
                    this.tableData = jsonData.slice(1).map((row) => {
                        const rowData = {};
                        this.tableHeaders.forEach((header, index) => {
                            rowData[header.value] = row[index];
                        });
                        return rowData;
                    });
                    
                    this.tableHeaders.unshift({text: 'Estado', value: 'status'}, {text: 'ID Cliente', value: 'ID Cliente'});

                    /* this.barcodes = this.tableData.filter(item => item.error ? false : true).map((item) => {
                        return { 
                            code: item['Código Alterno o de Barra'],
                            id: item['ID Producto'],
                        };
                    }); */
                };
                
                reader.readAsArrayBuffer(this.selectedFile);
                reader.onloadend = () =>  {
                    if(this.tableHeaders.length && this.tableData.length) {
                        this.loading = false;
                        this.uploadedFile = true;
                        this.showAlert("success", "Exito", "Las datos del archivo seleccionado fueron cargado correctamente.");
                    }
                    else {
                        throw new Error();
                    }
                };
            } catch (error) {
                console.log(error);
                this.loading = false;
                this.uploadedFile = false;
                this.showAlert("error", "Advertencia", "Ha ocurrido un error al cargar el archivo, vuelva a intentarlo más tarde.");
            }
        },
        async handleDataLoad() {            
            this.loading = true;
            try {
                this.tableHeaders.push({text: 'Codigo país', value: 'Codigo país'}, {text: 'Codigo departamento', value: 'Codigo departamento'}, {text: 'Codigo ciudad', value: 'Codigo ciudad'}, {text: 'Codigo actividad', value: 'Codigo actividad'});

                for (const item of this.tableData) {
                    const { country , department , municipality } = this.checkData(item);
                    
                    item['Codigo país'] = country.cod ? country.cod : null;
                    item['Codigo departamento'] = department.cod ? department.cod : null;
                    item['Codigo ciudad'] = municipality.cod ? municipality.cod : null;
                    item['País'] = country.value;
                    item['Departamento'] = department.value;
                    item['Ciudad'] = municipality.value;

                    if(!country.success) {
                        item.error = {
                            ...item.error,
                            country: true,
                        };
                    }
                    if(!department.success) {
                        item.error = {
                            ...item.error,
                            department: true,
                        };
                    }
                    if(!municipality.success) {
                        item.error = {
                            ...item.error,
                            municipality: true,
                        };
                    }

                    const activity = this.checkActivity(item);

                    item['Codigo actividad'] = activity.cod;

                    /* if(!provider){
                        const newProvider = await this.addProvider(item);
                        
                        if(newProvider && newProvider.ID_Provider) {
                            item['ID Proveedor'] = newProvider.ID_Provider;
                            this.providers = await this.$API.providers.getProviders().then(response => response);
                        }
                        else {
                            item.error = {
                                provider: 'Proveedor no registrado',
                                message: 'Ocurrio un error al insertar el proveedor no existente en la base de datos, debera ingresarlo manualmente.',
                            };
                            item['ID Proveedor'] = item.error.provider;
                        }
                    }
                    else {
                        item['ID Proveedor'] = provider.ID_Provider;
                    } */

                    const isDuplicated = await this.checkDuplicatedCustomer(item);
                    if(isDuplicated) {
                            item.error = {
                                isDuplicated
                            };
                    }
                
                }
                
                await this.generateIDs([...this.tableData]);

                //await this.getUniqueTableData();

                /* this.tableData.sort((a, b) => {
                    if (a.error && !b.error) {
                        return -1;
                    }
                    if (!a.error && b.error) {
                        return 1;
                    }
                    return 0;
                }); */

                this.loading = false;
            } 
            catch (error) {
                console.log(error);
                this.loading = false;
                this.showAlert("warning", "Advertencia", "Ha ocurrido un error al procesar el archivo, vuelva a intentarlo más tarde o contacte al soporte.");
            }
        },
        async generateIDs(data) {
            const initialID = await this.$API.customers.getInitialID('CLI');
            let index = 0;
            for (const item of data) {
                // Encuentra la última parte numérica de la cadena
                const match = initialID.match(/(\d+)$/);
                // Separa la parte de la cadena antes del número y el número en sí
                const string = initialID.slice(0, -match[0].length);
                const number = parseInt(match[0], 10) + index;
                // Genera un nuevo id con el número incrementado
                const ID = `${string}${number}`;
                item['ID Cliente'] = ID;
                //item['Codigo alterno'] = number;
                index++;
            }
        },
        async checkDuplicatedCustomer(value) {

            const data = {
                firstName: value['Nombre'], 
                lastName: value['Apellido'],
                mobileNumber: value['Celular'],
                email: value['Email'],
                isBusiness: value['Es negocio'] != undefined && value['Es negocio'].toLowerCase() == 'si' ? true : false,
                businessName: value['Nombre del negocio'],
                businessMobileNumber: value['Celular del negocio'],
                businessEmail: value['Email del negocio'],
            }
            const isDuplicated = await this.$API.customers.checkDuplicated(data)
                .then((res) => {
                    return res.duplicated;
                });

            return isDuplicated;
        },
        checkData(value) {
            const defaultCountry = this.countriesMH.find((item) => item.Valores == 'EL SALVADOR');
            const checkedCountry = this.countriesMH.find((item) => item.Valores == value['País']);

            const defaultDepartments = this.departmentsMH.find((item) => item.Valores == 'San Salvador');
            const checkedDepartments = this.departmentsMH.find((item) => item.Valores == value['Departamento']);

            const defaultMunicipalities = this.municipalitiesMH.find((item) => item.Valores == 'SAN SALVADOR' && item.Departamento == defaultDepartments.Codigo);
            const checkedMunicipalities = this.municipalitiesMH.find((item) => item.Valores == value['Ciudad'] && item.Departamento == checkedDepartments?.Codigo);

            let country = {};
            let department = {};
            let municipality = {};

            if(value['País'].toUpperCase() == 'EL SALVADOR') {
                if(checkedCountry && checkedDepartments && checkedMunicipalities) {
                    country = {
                        success: true,
                        value: checkedCountry.Valores,
                        cod: checkedCountry.Codigo,
                        id: checkedCountry.ID
                    }
                    department = {
                        success: true,
                        value: checkedDepartments.Valores,
                        cod: checkedDepartments.Codigo,
                        id: checkedDepartments.ID
                    }
                    municipality = {
                        success: true,
                        value: checkedMunicipalities.Valores,
                        cod: checkedMunicipalities.Codigo,
                        id: checkedMunicipalities.ID
                    }
                }
                else {
                    country =  {
                        success: checkedCountry ? true : false,
                        value: defaultCountry.Valores,
                        cod: defaultCountry.Codigo,
                        id: defaultCountry.ID
                    }
                    department = {
                        success: checkedDepartments ? true : false,
                        value: defaultDepartments.Valores,
                        cod: defaultDepartments.Codigo,
                        id: defaultDepartments.ID
                    }
                    municipality = {
                        success: checkedMunicipalities ? true : false,
                        value: defaultMunicipalities.Valores,
                        cod: defaultMunicipalities.Codigo,
                        id: defaultMunicipalities.ID
                    }
                }
            }
            else {
                country = {
                    success: true,
                    value: value['País'],
                    cod: checkedCountry?.Codigo,
                    id: checkedCountry?.ID
                }
                department = {
                    success: true,
                    value: value['Departamento'],
                    cod: checkedDepartments?.Codigo,
                    id: checkedDepartments?.ID
                }
                municipality = {
                    success: true,
                    value: value['Ciudad'],
                    cod: checkedMunicipalities?.Codigo,
                    id: checkedMunicipalities?.ID
                }
            }
            
            return {
                country,
                department,
                municipality
            }
        },
        checkActivity(value) {
            if(!value['Giro o actividad económica'] || value['Giro o actividad económica'] == '') {
                return {
                    empty: true,
                    cod: null,
                }
            }
            const checkedActivity = this.activitiesMH.find((item) => item.Valores == value['Giro o actividad económica']);

            if(checkedActivity) {
                return {
                    success: true,
                    cod: checkedActivity.Codigo,
                    id: checkedActivity.ID
                }
            }
            else {
                return {
                    success: false,
                    cod: null
                }
            }
        },
        async processData() {
            this.loading = true;
            this.alert.show = false;
            this.nextStep();
            try {
                if(!this.tableData.length) throw new Error('No ha seleccionado archivo con la información.');

                const customersData = [];

                for (const item of this.tableData) {
                    
                    if(item['Es negocio'] && item['Es negocio']?.toLowerCase() == 'si') {
                        const customer = {
                            BusinessName: item['Nombre del negocio'] || "",
                            BusinessPhone: item['Teléfono del negocio'] || "",
                            BusinessMobileNumber: item['Celular del negocio'] || "",
                            BusinessEmail: item['Email del negocio'] || "",
                            BusinessContactName: item['Nombre de contacto negocio'] || item['Nombre']?.concat('', item['Apellido']) || "",
                            BusinessContactPhone: item['Teléfono de contacto negocio'] || "",
                            BusinessContactMobile: item['Celular de contacto negocio'] || "",
                            BusinessContactEmail: item['Email de contacto negocio'] || "",
                            LegalName: item['Nombre legal o razón social'] || "",
                            LegalAddress: item['Dirección'] || "",
                            LegalNRC: item['NRC']?.toString().replaceAll("-", "") || "",
                            LegalNIT: item['NIT']?.toString().replaceAll("-", "") || "",
                            Prefijo: "",
                            LegalSector: item['Giro o actividad económica'] || "",
                            LegalEntity: item['Sociedad anónima o persona natural'] || "",
                            LegalTaxPayerSize: item['Categoría contribuyente'] || "",
                            LegalIsNonTaxable: 0,
                            LegalIsExempt: 0,
                            BusinessWebsite: item['Sitio web negocio'] || "",
                            Address: item['Dirección'] || "",
                            City: item['Ciudad'] || "",
                            State: item['Departamento'] || "",
                            Country: item['País'] || "",
                            Notes: "",
                            Status: "1",
                            SendEmail_BusinessEmail: 0,
                            SendSMS_BusinessMobile: 0,
                            SendWhatsapp_BusinessMobile: 0,
                            SendPushUp: 0,
                            Is_a_Business: 1,
                            Is_a_Ong: 0,
                            Is_a_PublicEntity: 0,
                            ID_Business: this.id_bussiness,
                            ID_Branch: this.id_branch,
                            ID_User_Insert: this.id_user,
                            Department: item['Codigo departamento'] ? item['Codigo departamento'] : item['Departamento'] || "",
                            Municipality: item['Codigo ciudad'] ? item['Codigo ciudad'] : item['Ciudad'] || "",
                            codActividad: item['Codigo actividad'] || "",
                            descActividad: item['Giro o actividad económica'] || "",
                            dui: item['DUI']?.toString().replaceAll("-", "") || "",
                            ID_DocumentType: 0,
                            LimitCredit: 0,
                            OldID: item['Código alterno'] || 0,
                            isGlobalBranch: 1,
                            RemoveRestrictionPublicEntity: 0,
                            CreditPeriod: 0,
                            CreditStatus: 'BLOQUEADO',
                            ZoneClient: "",
                            CountryMH: item['País'],
                            ID_Customer: item['ID Cliente']
                        };
                        customersData.push(customer);
                    }
                    else {
                        const customer = {
                            FirstName: item['Nombre'] || "",
                            LastName: item['Apellido'] || "",
                            PhoneNumber: item['Teléfono'] || "",
                            MobileNumber: item['Celular'] || "",  
                            LegalName: item['Nombre legal o razón social'] || "",
                            LegalAddress: item['Dirección'] || "",
                            LegalNRC: item['NRC']?.toString().replaceAll("-", "") || "",
                            LegalNIT: item['NIT']?.toString().replaceAll("-", "") || "",
                            dui: item['DUI']?.toString().replaceAll("-", "") || "",
                            Prefijo: "",
                            LegalSector: item['Giro o actividad económica'] || "",
                            LegalEntity: item['Sociedad anónima o persona natural'] || "",
                            LegalTaxPayerSize: item['Categoría contribuyente'] || "",
                            LegalIsNonTaxable: 0,
                            LegalIsExempt: 0,
                            Email: item['Email'] || "",
                            Gender: item['Genero'] || "",
                            Age: item['Edad'] || "",
                            DateofBirth: item['Fecha de nacimiento'] || "",
                            Address: item['Dirección'] || "",
                            City: item['Ciudad'] || "",
                            State: item['Departamento'] || "",
                            Country: item['País'] || "",
                            Notes: "",
                            Status: "1",
                            SendEmail_BusinessEmail: 0,
                            SendSMS_BusinessMobile: 0,
                            SendWhatsapp_BusinessMobile: 0,
                            SendPushUp: 0,
                            Is_a_Business: 0,
                            Is_a_Ong: 0,
                            Is_a_PublicEntity: 0,
                            ID_Business: this.id_bussiness,
                            ID_Branch: this.id_branch,
                            ID_User_Insert: this.id_user,
                            Department: item['Codigo departamento'] ? item['Codigo departamento'] : item['Departamento'] || "",
                            Municipality: item['Codigo ciudad'] ? item['Codigo ciudad'] : item['Ciudad'] || "",
                            codActividad: item['Codigo actividad'] || "",
                            descActividad: item['Giro o actividad económica'] || "",
                            ID_DocumentType: 0,
                            LimitCredit: 0,
                            OldID: item['Código alterno'] || 0,
                            isGlobalBranch: 1,
                            RemoveRestrictionPublicEntity: 0,
                            CreditPeriod: 0,
                            CreditStatus: 'BLOQUEADO',
                            ZoneClient: "",
                            CountryMH: item['País'],
                            ID_Customer: item['ID Cliente']
                        };
                        customersData.push(customer);
                    }
                    this.customersAllowed.push(item);
                }

                await this.$API.customers.importCustomers({ customersData: customersData })
                    .then(res => {
                        if(res.success) {
                            //console.log(res);
                            this.loading = false;
                            this.showAlert("success", "Exito", "Todos los clientes fueron creados exitosamente." );
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        this.customersNotAllowed = this.customersAllowed;
                        this.customersAllowed = [];
                        throw new Error('Ha ocurrido un error en el procesamiento, vuelva a intentarlo más tarde. Si el error persiste, contactarse con soporte.');
                    });
                
            } catch (error) {
                console.log(error);
                this.processError = true;
                this.loading = false;
                this.showAlert("warning", "Advertencia", error.message ? error.message : "Ha ocurrido un error al procesar el archivo, vuelva a intentarlo más tarde.");
            }
        },
        exportToExcel(value) {
            if(value === 'ERROR') {
                const headers = this.tableHeaders.map(header => header.text);
                const data = this.customersNotAllowed.map(item => this.tableHeaders.map(header => item[header.value]));
    
                const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Plantilla de clientes');
    
                XLSX.writeFile(workbook, 'plantilla_clientes_errores.xlsx');
            } else if(value === 'SUCCESS') {
                const headers = this.tableHeaders.map(header => header.text);
                const data = this.customersAllowed.map(item => this.tableHeaders.map(header => item[header.value]));
    
                const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Plantilla de clientes');
    
                XLSX.writeFile(workbook, 'plantilla_clientes_procesados.xlsx');
            }
        },
        onClickProcess() {
            if(this.customersError) {
                this.showAlert("confirm-danger", "Atención", "Existen registros cuyos datos de pais, departamento o municipio no pudieron validarse, de continuar se insertaran los datos por defecto en dichos registros.", this.processData, "¿Esta seguro que desea continuar?");
            }
            else {
                this.showAlert("confirm", "Atención", "¿Esta seguro que desea continuar?", this.processData);
            }
        },
        showError(values) {
            if(values.isDuplicated) {
                return this.showAlert("warning", "Advertencia", `Este cliente contiene información repetida que ya se encuentran en los registros del sistema.`);
            }

            this.showAlert("warning", "Advertencia", `La información de este cliente presenta errores en los valores de ${values.country ? 'PAÍS,' : ''} ${values.department ? 'DEPARTAMENTO,' : ''} ${values.municipality ? 'MUNICIPIO,' : ''} los cuales no coinciden con los datos del sistema del MH. De continuar el proceso, se cargaran los datos por defecto (Pais: EL SALVADOR, Departamento: San Salvador, Ciudad: SAN SALVADOR).`);
        },
        resetData() {
            this.tableHeaders = [];
            this.tableData = [];
            this.customersError = null;
            this.processError = null;
            this.uploadedFile = false;
        },
        resetAll() {
            this.step = 1;
            this.loading = false;
            this.uploadedFile = false;
            this.selectedFile = null;
            this.tableHeaders = [];
            this.tableData = [];
            this.customersAllowed = [];
            this.customersNotAllowed = [];
            this.showProcessed = false;
            this.showNotProcessed = false;
            this.customersError = null;
            this.processError = null;
        },
        downloadTemplate() {
            const filePath = '/Plantilla_Clientes.xlsx';
            const link = document.createElement('a');
            link.href = filePath;
            link.download = 'Plantilla_Clientes.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};
</script>

<style scoped>

.card-step {
    min-height: 400px; 
    width: 100%; 
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.col-input-file {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 1rem;
}

.btn-import {
  background-color: #3498db !important;
  color: white;
  min-width: 250px !important;
  height: 40px !important;
}

.btnoption {
  background-color: #3498DB !important;
  color: white;
}

.btncontinue {
  background-color: rgba(80, 80, 80, 1) !important;
  color: white;
}

.btndisable {
    background-color: #41464c !important;
    color: white;
}


</style>

<style>

.mdi-file-excel::before {
    font-size: 32px !important;
}

.data-table .v-data-table__wrapper thead th {
    height: 35px !important;
    text-wrap: nowrap;
    min-width: 100px;

}
.data-table .v-data-table__wrapper tbody tr {
    height: 35px !important;
    text-wrap: nowrap;
    min-width: 100px;
}
.data-table .v-data-table__wrapper tbody td {
    height: 35px !important;
    text-wrap: nowrap;
    min-width: 100px;
}

.tabs-seg .v-slide-group__content {
    justify-content: space-between;
}

</style>